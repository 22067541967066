import React, { createContext, useState, useContext } from "react";

const DocumentReuseContext = createContext();

export function DocumentReuseProvider({ children }) {
  const [hasDocumentReuse, setHasDocumentReuse] = useState(false);
  const [documentReuseData, _setDocumentReuseData] = useState(undefined);

  const setDocumentReuse = (v) => {
    setHasDocumentReuse(v);
  };

  const setDocumentReuseData = (data) => {
    _setDocumentReuseData(data);
  };

  return (
    <DocumentReuseContext.Provider
      value={{ hasDocumentReuse, setDocumentReuse, documentReuseData, setDocumentReuseData }}
    >
      {children}
    </DocumentReuseContext.Provider>
  );
}

export function useDocumentReuse() {
  return useContext(DocumentReuseContext);
}
