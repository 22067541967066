import VariablesStore from ".";
import { makeAutoObservable } from "mobx";
import { Countries } from "../../types";

interface File {
  contentType: string;
  id: number;
  keys: string[];
  key?: string;
  required?: boolean;
  title?: string;
  type: string;
}

export default class DocumentVariablesStore {
  documentIssuedCountry: Countries | null = null;
  documentDateFormat: string | null = null;
  selectedDocumentType = "";
  detectedDocumentType = "";
  selectedSendDocumentType: "upload" | "picture" | null = null;
  selectedDocumentFileDD = "";
  documentUrlFront = "";
  documentUrlBack = "";
  isDoubleSided: string | null = null;
  textFieldsData: { [key: string]: any } | null = null;

  uploadedFiles: File[] = [];
  multipleUploadedFiles: File[] = [];

  constructor(private variablesStore: VariablesStore) {
    makeAutoObservable(this);
  }

  setDocumentIssuedCountry = (documentIssuedCountry: DocumentVariablesStore["documentIssuedCountry"]) => {
    this.setSelectedDocumentType("ANY");
    if (documentIssuedCountry) this.variablesStore.person.setPersonAddressCountry(documentIssuedCountry);

    if (documentIssuedCountry !== "BR") {
      this.variablesStore.person.setPersonPhoneNumber("");
      this.variablesStore.person.setPersonBirthDate("");
      this.variablesStore.company.setCompanyPhoneNumber("");
      this.variablesStore.pfpf.setPFPFPhoneNumber("");
      this.variablesStore.company.setCompanyEmployeePhoneNumber("");
    }

    this.documentIssuedCountry = documentIssuedCountry;
  };
  setDocumentDateFormat = (documentDateFormat: string) => {
    this.documentDateFormat = documentDateFormat;
  };
  setSelectedDocumentType = (selectedDocumentType: string) => {
    this.selectedDocumentType = selectedDocumentType;
  };
  setDetectedDocumentType = (detectedDocumentType: string) => {
    this.detectedDocumentType = detectedDocumentType;
  };
  setSelectedSendDocumentType = (selectedSendDocumentType: DocumentVariablesStore["selectedSendDocumentType"]) => {
    this.selectedSendDocumentType = selectedSendDocumentType;

    if (selectedSendDocumentType === "picture") this.setSelectedDocumentFileDD("documentDetector");
    else this.setSelectedDocumentFileDD("");
  };
  setSelectedDocumentFileDD = (selectedDocumentFileDD: DocumentVariablesStore["selectedDocumentFileDD"]) => {
    this.selectedDocumentFileDD = selectedDocumentFileDD;
  };
  setDocumentUrlFront = (documentUrlFront: string) => {
    this.documentUrlFront = documentUrlFront;
  };
  setDocumentUrlBack = (documentUrlBack: string) => {
    this.documentUrlBack = documentUrlBack;
  };

  setMultipleUploadedFiles = (files: File[]) => {
    this.multipleUploadedFiles = files;
  };

  setUploadedFiles = (files: File[]) => {
    this.uploadedFiles = files;
  };

  setisDoubleSided = (isDoubleSided: DocumentVariablesStore["isDoubleSided"]) => {
    this.isDoubleSided = isDoubleSided;
  };

  setTextFieldsData = (textFieldsData: DocumentVariablesStore["textFieldsData"]) => {
    this.textFieldsData = textFieldsData;
  };
}
